import React, { useEffect, useState } from 'react'
import IMG_ISOTIPO_CONCURSO from '../../assets/img/isotipo-concurso.png'
import { useNavigate, useParams } from 'react-router-dom';
import sessionUtils from '../../utilities/SessionHandler'
import userService from '../../services/users';
import ModalComponent from '../../components/Private/ModalComponent';

export default function PasswordSetNew() {

    const navigateTo = useNavigate()
    const { user_id, token } = useParams()

    const [callbackData, setCallbackData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalBody, setModalBody] = useState('')

    const [formData, setFormData] = useState(
        { userPassword: '' }
    )

    const handleCallbackOnHide = (data) => {
        navigateTo(data.destination)
    }

    const handleIntegrityCheck = async () => {
        try {
            const result = await userService.checkPasswordRecoveryToken(
                {
                    id: user_id,
                    userPasswordRecoveryToken: token
                }
            );

        } catch (error) {

            setCallbackData({ destination: "/" })

            setModalTitle('Error')
            setModalBody(error.response.data.messages.error)
            setShowModal(true)
        }
    }

    const handleRemoveCredentials = () => {
        sessionUtils.removeCredentials()
    }

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async () => {
        try {
            const result = await userService.setNewPassword(
                user_id,
                {
                    userPasswordRecoveryToken: token,
                    userPassword: formData.userPassword
                }
            );

            setCallbackData({ destination: "/sign-in" })
            setModalTitle('Éxito')
            setModalBody('Se ha restablecido tu contraseña')
            setShowModal(true)
            setFormData({ userPassword: '' })

        } catch (error) {

            setModalTitle('Error')
            setModalBody(error.response.data.messages.error)
            setShowModal(true)
        }
    }

    const renderPasswordSetNewForm = () => {
        return (
            <>
                <div className="form-outline mb-4">
                    <input
                        type="password"
                        className="form-control form-control-lg rounded-pill"
                        placeholder="Escribe la nueva contraseña"
                        onChange={handleInputChange}
                        name="userPassword"
                        value={formData.userPassword}
                        autoComplete='new-password'
                    />
                </div>
                <input
                    type="button"
                    className="btn btn-primary rounded-pill w-100 my-3 py-2 text-white"
                    value="Enviar"
                    onClick={handleSubmit}
                />
                <input
                    type="button"
                    className="btn btn-outline-primary rounded-pill w-100 my-3 py-2 text-primary"
                    value="Volver"
                    onClick={() => navigateTo('/')}
                />
            </>
        )
    }

    useEffect(() => {
        handleRemoveCredentials();
        handleIntegrityCheck();
    }, []);

    return (

        <>
            <main id="welcome">
                <div className="container-fluid vh-100">
                    <div className="row">
                        <div className="vh-100 col-md-12 d-flex align-items-center px-lg-5 py-5">
                            <div className="card no-shadow m-auto p-4 bg-light-gray text-center" style={
                                {
                                    minWidth: '542px',
                                }
                            }>
                                <h2 className="card-title fw-bold mb-3">Olvidaste tu Contraseña</h2>
                                <div className="card-body">
                                    <p className='my-4'>
                                        Te enviaremos las instrucciones para restablecer tu contraseña por correo electrónico
                                    </p>
                                    <form action="">
                                        {renderPasswordSetNewForm()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
            <ModalComponent
                id='alertModal'
                title={modalTitle}
                show={showModal}
                setShow={setShowModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <p>
                    {modalBody}
                </p>
                <div className='d-flex justify-content-end mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowModal(false)
                        }}
                    >
                        Ok
                    </button>
                </div>
            </ModalComponent>
        </>
    )
}
