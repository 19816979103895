import React, { useEffect } from 'react'
import IMG_USER_AVATAR from '../../assets/img/dashboard/user-avatar.svg'
import IMG_CBP from '../../assets/img/concurso_buenas_practicas.png'
import { useNavigate } from 'react-router-dom'
import OffcanvasComponent from './OffcanvasComponent'
import sessionHandler from '../../utilities/SessionHandler'
import companyService from '../../services/companies'
import fileService from '../../services/files'

export default function Navbar(props) {

    const navigateTo = useNavigate();

    const {
        companyUser
    } = props

    const [userAvatar, setUserAvatar] = React.useState(null)

    const [company, setCompany] = React.useState({
        companyName: 'Cargando...'
    })

    const credentials = sessionHandler.getParsedCredentials()

    const [offcanvasShow, setOffcanvasShow] = React.useState(false);

    const handleHome = () => {
        navigateTo('/home')
    }

    const handleFileImage = async (item) => {
        const fileResult = await fileService.get(item.avatar_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        setUserAvatar(linkSource)
    }

    const handleNotifications = () => {
        alert('TODO: Notificaciones')
    }

    const handleCompanyLoad = async () => {
        if (companyUser) {
            try {
                const result = await companyService.get(credentials.company_id)
                setCompany(result)
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        credentials?.avatar_id && handleFileImage(credentials)
        handleCompanyLoad()
    }, [])

    return (
        <>

            <OffcanvasComponent
                show={offcanvasShow}
                setShow={setOffcanvasShow}
            />

            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top py-3 px-lg-5">
                    <div className="container-fluid px-lg-5">
                        <a className="navbar-brand ms-lg-0 ms-sm-4 ms-2 py-0 react-link" onClick={handleHome}>
                            <img
                                src={IMG_CBP}
                                className="img-fluid"
                                style={
                                    {
                                        maxWidth: '320px'
                                    }
                                }
                            />
                        </a>
                        {
                            companyUser && (
                                <div className="box-company-name py-2 px-5 ms-auto">
                                    <h3 className="h2 fw-light mb-0">
                                        {company.companyName}
                                    </h3>
                                </div>
                            )
                        }
                        <div className="d-flex align-items-center ms-auto">
                            {/*
                            <a
                                className="notifications me-lg-4 me-0 react-link"
                                onClick={handleNotifications}
                            >
                                <i className="bi bi-bell-fill"></i>
                            </a>
                            */}
                            <a className="btn user-avatar" role="button" onClick={
                                () => {
                                    setOffcanvasShow(true)
                                }
                            }>
                                <img
                                    src={
                                        credentials && credentials.avatar_id ?
                                            userAvatar :
                                            IMG_USER_AVATAR
                                    }
                                    alt=""
                                    className="rounded-circle"
                                    height="80"
                                    width="80" />
                            </a>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
