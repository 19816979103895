import React, { useState, useEffect } from 'react'
import sessionHandler from '../../../utilities/SessionHandler'

import { useNavigate } from 'react-router-dom'

import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import roleService from '../../../services/roles'
import userService from '../../../services/users'

export default function List() {

  const navigateTo = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)
  const [addModalTitle, setAddModalTitle] = useState('Crear usuario')
  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('Editar usuario')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar usuario')

  const [tabList, setTabList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const formDefaultData = {
    role_id: 3,
    userEmail: '',
    userFullName: '',
    userPassword: '',
    userDNI: '',
    userPhone: '',
  }

  const [formData, setFormData] = useState(formDefaultData)

  const credentials = sessionHandler.getParsedCredentials();

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  useEffect(() => {
    let tabs = [];

    if (credentials.role_id === '1') {
      tabs.push({
        title: 'Superadministrador',
        active: true,
        filter: [
          {
            criteria: 'role',
            operator: 'equals',
            value: "Superadministrador"
          }
        ]
      })
    } 
    tabs.push(
      {
        title: 'Administrador',
        active: false,
        filter: [
          {
            criteria: 'role',
            operator: 'equals',
            value: "Administrador"
          }
        ]
      })
    tabs.push({
      title: 'Agente',
      active: false,
      filter: [
        {
          criteria: 'role',
          operator: 'equals',
          value: "Agente"
        }
      ]
    })
    setTabList(tabs)
  }, [])

  const getFormDOM = () => {
    return <>
      <div className='mb-3'>
        <label htmlFor='userFullName' className='form-label'>Nombre completo del usuario</label>
        <input
          name='userFullName'
          id='userFullName'
          type='text'
          className='form-control'
          placeholder='Nombre completo'
          value={formData.userFullName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.role_id) >= parseInt(formData.role_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='userEmail' className='form-label'>Correo electrónico</label>
        <input
          name='userEmail'
          id='userEmail'
          type='email'
          className='form-control'
          placeholder='Correo electrónico'
          value={formData.userEmail}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.role_id) >= parseInt(formData.role_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='userPassword' className='form-label'>Contraseña</label>
        <input
          name='userPassword'
          id='userPassword'
          type='password'
          className='form-control'
          placeholder='Contraseña'
          value={formData.userPassword}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.role_id) >= parseInt(formData.role_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='userDNI' className='form-label'>RUT</label>
        <input
          name='userDNI'
          id='userDNI'
          type='text'
          className='form-control'
          placeholder='RUT'
          value={formData.userDNI}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.role_id) >= parseInt(formData.role_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='userPhone' className='form-label'>Teléfono</label>
        <input
          name='userPhone'
          id='userPhone'
          type='text'
          className='form-control'
          placeholder='Teléfono'
          value={formData.userPhone}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.role_id) >= parseInt(formData.role_id)
          }
        />
      </div>
      {
        parseInt(credentials.role_id) < parseInt(formData.role_id) ?
          <div className='mb-3'>
            <label htmlFor='role_id' className='form-label'>Rol</label>
            <select
              name='role_id'
              id='role_id'
              className='form-select'
              value={formData.role_id}
              onChange={
                (event) => {
                  handleFieldChange(event)
                }
              }
            >
              {
                roleList
                  .filter(
                    (item) => {
                      return parseInt(credentials.role_id) < parseInt(item.id)
                    }
                  )
                  .map(
                    (item) => {
                      return <option
                        key={item.id}
                        value={item.id}
                      >
                        {item.roleName}
                      </option>
                    }
                  )
              }
            </select>
          </div>
          :
          <div className='mb-3'>
            <input type='hidden' name='role_id' value={formData.role_id} />
            <p>
              <strong>Rol:</strong> {
                roleList.find(
                  (item) => {
                    return item.id === formData.role_id
                  }
                )?.roleName
              }
            </p>
          </div>
      }

    </>
  }

  const handleDataCreate = async () => {
    try {
      await userService.create(formData)
      await handleDataLoad()
      setShowAddModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataUpdate = async (itemId) => {
    try {
      await userService.update(itemId, formData)
      await handleDataLoad()
      setShowEditModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataDelete = async (itemId) => {
    await userService.remove(itemId)
    await handleDataLoad()
  }

  const handleRoleListDataLoad = async () => {
    const result = await roleService.list()
    setRoleList(result)
  }

  const handleDataLoad = async () => {
    await handleRoleListDataLoad()
    const result = await userService.list();
    setListData(result)
  }

  useEffect(() => {
    handleDataLoad()
  }, [])

  useEffect(() => {
  }, [listData])


  return (
    <>
      <Navbar />
      {
        listData != null &&
        <ListSection
          title='Usuarios de Sistema'
          hasSearch={true}
          hasYear={false}
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/home')
              }
            }
          }
          tabs={
            [
              ...tabList
            ]
          }
          actions={
            {
              title: 'Crear usuario',
              onClick: () => {
                setFormData(formDefaultData)
                setShowAddModal(true)
              }
            }
          }
          columns={
            [
              {
                title: 'Nombre completo',
                field: 'userFullName',
                type: 'text',
              },
              {
                title: 'Correo electrónico',
                field: 'userEmail',
                type: 'text',
              },
              {
                title: 'RUT',
                field: 'userDNI',
                type: 'text',
              },
              {
                title: 'Rol',
                field: 'role',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []

                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-pencil',
                    tooltip: 'Editar',
                    onClick: () => {
                      setFormData({
                        userFullName: item.userFullName,
                        userEmail: item.userEmail,
                        userPassword: '',
                        userDNI: item.userDNI,
                        userPhone: item.userPhone,
                        role_id: item.role_id,
                      })
                      setSelectedItemId(item.id)
                      setEditModalTitle('Editar a ' + item.userFullName)
                      setShowEditModal(true)
                    }
                  }
                )
                if (parseInt(credentials.role_id) < parseInt(item.role_id)) {
                  actions.push(
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Eliminar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Eliminar a ' + item.userFullName)
                        setShowDeleteModal(true)
                      }
                    }
                  )
                }


                return {
                  userFullName: item.userFullName,
                  userEmail: item.userEmail,
                  userDNI: item.userDNI,
                  role: roleList?.find(
                    (role) => {
                      return role.id === item.role_id
                    }
                  )?.roleName,
                  actions: [...actions],
                }
              })
            ]
          }
          pagination={
            {
              itemsPerPage: 5,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
        />
      }
      <ModalComponent
        id='createModal'
        title={addModalTitle}
        show={showAddModal}
        setShow={setShowAddModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowAddModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataCreate()
            }}
          >
            Crear
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='editModal'
        title={editModalTitle}
        show={showEditModal}
        setShow={setShowEditModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowEditModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataUpdate(selectedItemId)
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar este usuario?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
