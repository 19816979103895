import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'bootstrap'

export default function TooltipComponent(props) {

    const {
        id,
        title,
        children,
    } = props

    const [tooltip, setTooltip] = useState(null)
    
    const tooltipRef = useRef(null)

    useEffect(() => {
        setTooltip(title)
    }, [title])

    useEffect(() => {
        if(!tooltip) return
        const tooltipDOM = new Tooltip(tooltipRef.current, {
            title: tooltip,
            placement: 'top',
            trigger: 'hover',
        })
        return () => {
            tooltipDOM.dispose()
        }
    }, [tooltip])

    return (
        <>
            <span ref={tooltipRef} id={id}>
                {children}
            </span>
        </>
    )
    
}