import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import sessionUtils from '../../../../utilities/SessionHandler'
import ModalComponent from '../../../../components/Private/ModalComponent'
import categoryGroupService from '../../../../services/categorygroups'
import categoryService from '../../../../services/categories'
import tagService from '../../../../services/tags'
import applicationService from '../../../../services/applications'
import fileService from '../../../../services/files'
import applicationFileService from '../../../../services/applicationFiles'
import { useReactToPrint } from 'react-to-print'

export default function Printable(
    {
        contestData,
        formTitle,
        backRoute,
        itemData = null,
        readOnly = false,
        assignableUser = false
    }
) {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const navigateTo = useNavigate()
    let credentials = sessionUtils.getParsedCredentials()
    const textareaRef = useRef()

    const [showPreOperationModal, setShowPreOperationModal] = useState(false)
    const [preOperationModalTitle, setPreOperationModalTitle] = useState('Confirmación')
    const [preOperationModalMessage, setPreOperationModalMessage] = useState('¿Está seguro de querer guardar y enviar?')

    const [newBackRoute, setNewBackRoute] = useState(backRoute)
    const [callbackData, setCallbackData] = useState(null)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [saveModalTitle, setSaveModalTitle] = useState('Éxito')
    const [showSaveAndSendModal, setShowSaveAndSendModal] = useState(false)
    const [saveAndSendModalTitle, setSaveAndSendModalTitle] = useState('Éxito')

    const [showGenericModal, setShowGenericModal] = useState(false)
    const [genericModalTitle, setGenericModalTitle] = useState('Operación completada')
    const [genericModalMessage, setGenericModalMessage] = useState('')

    const [showGenericActionModal, setShowGenericActionModal] = useState(false)
    const [genericActionModalTitle, setGenericActionModalTitle] = useState('Operación completada')
    const [genericActionModalButton, setGenericActionModalButton] = useState('')
    const [genericActionModalMessage, setGenericActionModalMessage] = useState('')

    const [formUserId, setFormUserId] = useState(null)

    const [dynamicFormList, setDynamicFormList] = useState([])
    const [formData, setFormData] = useState({
        applicationTitle: itemData?.applicationTitle || '',
        applicationDescription: itemData?.applicationDescription || '',
        applicationBenefits: itemData?.applicationBenefits || '',
        applicationImplementationDate: itemData?.applicationImplementationDate || '',
        files: itemData.files || [],
        dynamic: itemData.dynamic || [],
    })

    const handleCategoryLoad = async () => {
        const result = await categoryGroupService.byContest(contestData.id)
        setDynamicFormList(result)
        let dynamic = []
        result.forEach(async (categoryGroup, indexCG) => {
            dynamic.push({
                categoryGroupId: categoryGroup.id,
                category: {}
            })
        })
        if (itemData?.dynamic) {
            itemData.dynamic.forEach((item) => {
                const dynamicIndex = dynamic.findIndex((item2) => item2.categoryGroupId === item.categoryGroupId)
                dynamic[dynamicIndex] = item
            })
        }
        setFormData({
            ...formData,
            dynamic: dynamic
        })
    }

    const handleFormChange = (event) => {

        const { name, value } = event.target
        const inputData = event.target.getAttribute('input-data')
        const inputTarget = event.target.getAttribute('input-target')
        const categoryGroupId = event.target.getAttribute('category-group-id')

        if (inputData === 'static') {
            setFormData({
                ...formData,
                [name]: value
            })
        } else if (inputData === 'dynamic') {
            if (inputTarget === 'categories') {
                const dynamicIndex = formData.dynamic.findIndex((item) => item.categoryGroupId === categoryGroupId)
                const dynamicCopy = [...formData.dynamic]
                if (dynamicCopy.length > 0) {
                    dynamicCopy[dynamicIndex].category = {
                        categoryId: value,
                        tags: []
                    }
                } else {
                    dynamicCopy.push({
                        categoryGroupId: categoryGroupId,
                        category: {
                            categoryId: value,
                            tags: []
                        }
                    })
                }
                setFormData({
                    ...formData,
                    dynamic: dynamicCopy
                })
            } else if (inputTarget === 'tags') {
                const dynamicIndex = formData.dynamic.findIndex((item) => item.categoryGroupId === categoryGroupId)
                const dynamicCopy = [...formData.dynamic]
                const categoryIndex = dynamicCopy[dynamicIndex].category.tags.findIndex((item) => item === value)

                const categoryType = dynamicFormList
                    .find((item) => item.id === categoryGroupId)
                    .categories.find((item) => item.id === dynamicCopy[dynamicIndex].category.categoryId).categoryType

                if (categoryType === 'multiple') {
                    if (categoryIndex === -1) {
                        dynamicCopy[dynamicIndex].category.tags.push(value)
                    } else {
                        dynamicCopy[dynamicIndex].category.tags.splice(categoryIndex, 1)
                    }
                } else {
                    dynamicCopy[dynamicIndex].category.tags = [value]
                }

                setFormData({
                    ...formData,
                    dynamic: dynamicCopy
                })
            }
        }
    }

    const handleFormSave = async () => {
        let newFormData = {
            ...formData,
            applicationStatusDraft: "Devuelta",
            applicationStatusAfterDraft: "En revisión",
            applicationStatusEditedByAdmin: "Editada",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        }
        await applicationService.update(itemData.id, newFormData)
        setShowSaveModal(true)
    }

    const handleFormSaveAndSend = async () => {
        let newFormData = {
            ...formData,
            applicationStatusDraft: "Enviada",
            applicationStatusAfterDraft: "En revisión",
            applicationStatusEditedByAdmin: "Editada",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        }
        await applicationService.update(itemData.id, newFormData)
        setCallbackData({ destination: "/applications/enviadas" })
        setShowSaveAndSendModal(true)
    }

    const handleCallbackOnHide = (data) => {
        navigateTo(data.destination)
    }

    const handleFileUpload = async (event) => {

        try {
            if (event.target.files.length > 0) {
                let fileName = event.target.files[0].name

                const newFile = await fileService.create({
                    file: event.target.files[0],
                })

                if (newFile?.error === true) {
                    alert("Error al subir el archivo: " + newFile.message)
                    return
                }

                let newApplicationFile = await applicationFileService.create({
                    application_id: itemData.id,
                    applicationFileName: fileName,
                    file_id: newFile.id
                })
                let newFiles = [...formData.files]
                newFiles.push(newApplicationFile)
                setFormData({
                    ...formData,
                    files: newFiles
                })
            }
        } catch (error) {
            alert("Error al subir el archivo")
        }
    }

    const handleFileDownload = async (item) => {
        const fileResult = await fileService.get(item.file_id)
        console.log(fileResult)
        if (!fileResult || !fileResult.fileBase64) {
            if (fileResult.fileMimeType === "application/x-empty") {
                return alert("El archivo no existe, está vacío o corrupto")
            } else
                return alert(fileResult.fileErrorMessage)
        }
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        const downloadLink = document.createElement("a")
        const fileName = item.applicationFileName
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    const handleFileDelete = async (item) => {
        await applicationFileService.remove(item.id)
        let newFiles = [...formData.files]
        newFiles.splice(newFiles.findIndex((file) => file.id === item.id), 1)
        setFormData({
            ...formData,
            files: newFiles
        })
    }

    const handleSetApplicationUser = async () => {
        await applicationService.patch(itemData.id, {
            user_id: credentials.id
        })
        setFormUserId(credentials.id)
    }

    const handleUnsetApplicationUser = async () => {
        await applicationService.patch(itemData.id, {
            user_id: null
        })
        setFormUserId(null)
    }

    const handleApplicationAfterDraftReturn = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusDraft: "Devuelta",
            applicationStatusAfterDraft: "En revisión",
            applicationStatusReturnMotive: textareaRef.current.value,
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/enviadas" })
        setGenericModalMessage("La postulación ha sido devuelta al postulante")
        setShowGenericModal(true)
        textareaRef.current.value = ""
    }

    const handleApplicationAfterDraftApprove = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusAfterDraft: "Aprobada",
            applicationStatusPreselection: "En revisión",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/enviadas" })
        setGenericModalMessage("La postulación ha sido aprobada")
        setShowGenericModal(true)
    }

    const handleApplicationAfterDraftReject = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusAfterDraft: "Rechazada",
            applicationStatusPreselection: "",
            applicationStatusRejectMotive: textareaRef.current.value,
            applicationStatusReturnMotive: "",
        })
        setCallbackData({ destination: "/applications/enviadas" })
        setGenericModalMessage("La postulación ha sido rechazada")
        setShowGenericModal(true)
        textareaRef.current.value = ""
    }

    const handleApplicationPreselectionApprove = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusPreselection: "Seleccionada",
            applicationStatusFinalist: "En revisión",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/preseleccion" })
        setGenericModalMessage("La postulación ha sido seleccionada")
        setShowGenericModal(true)
    }

    const handleApplicationPreselectionReject = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusPreselection: "No seleccionada",
            applicationStatusRejectMotive: textareaRef.current.value,
            applicationStatusReturnMotive: "",
        })
        setCallbackData({ destination: "/applications/preseleccion" })
        setGenericModalMessage("La postulación ha sido marcada como no seleccionada")
        setShowGenericModal(true)
        textareaRef.current.value = ""
    }

    const handleApplicationFinalistApprove = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusFinalist: "Seleccionada",
            applicationStatusFinal: "No destacada",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/finalistas" })
        setGenericModalMessage("La postulación ha sido seleccionada")
        setShowGenericModal(true)
    }

    const handleApplicationFinalistReject = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusFinalist: "No seleccionada",
            applicationStatusRejectMotive: textareaRef.current.value,
            applicationStatusReturnMotive: "",
        })
        setCallbackData({ destination: "/applications/finalistas" })
        setGenericModalMessage("La postulación ha sido marcada como no seleccionada")
        setShowGenericModal(true)
        textareaRef.current.value = ""
    }

    const handleApplicationFinalWinner = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusFinal: "Ganadora",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/destacadas" })
        setGenericModalMessage("La postulación ha sido marcada como ganadora")
        setShowGenericModal(true)
    }

    const handleApplicationFinalFeature = async () => {
        await applicationService.patch(itemData.id, {
            applicationStatusFinal: "Destacada",
            applicationStatusReturnMotive: "",
            applicationStatusRejectMotive: "",
        })
        setCallbackData({ destination: "/applications/destacadas" })
        setGenericModalMessage("La postulación ha sido marcada como destacada")
        setShowGenericModal(true)
    }

    useEffect(() => {
        handleCategoryLoad()
    }, [])

    useEffect(() => {
        if (itemData) {
            setFormData({
                ...formData,
                applicationImplementationDate: itemData.applicationImplementationDate,
                applicationTitle: itemData.applicationTitle,
                applicationDescription: itemData.applicationDescription,
                applicationBenefits: itemData.applicationBenefits,
            })
            setFormUserId(itemData.user_id)

            if (itemData.applicationStatusFinal !== '') {
                setNewBackRoute('/applications/destacadas')
            }
            else if (itemData.applicationStatusFinalist !== '') {
                setNewBackRoute('/applications/finalistas')
            }
            else if (itemData.applicationStatusPreselection !== '') {
                setNewBackRoute('/applications/preseleccion')
            }
            else if (itemData.applicationStatusAfterDraft !== '') {
                setNewBackRoute('/applications/enviadas')
            }

        }
    }, [])

    useEffect(() => {
        //console.log(dynamicFormList)
    }, [dynamicFormList])

    useEffect(() => {
        //console.log(formData)
    }, [formData])

    return (
        <>
            <main className="theme-light">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-7 order-2 order-lg-1 d-flex align-items-center justify-content-between">
                            <h2 className="page-title fw-bold mb-0">
                                <div className='d-flex justify-content-start'>
                                    {
                                        <div className="mt-lg-0 mt-4 me-3">
                                            <button className="btn btn-outline-primary px-3 py-2" type="button" onClick={
                                                () => {
                                                    navigateTo(newBackRoute)
                                                }
                                            }>
                                                <i className="fas fa-arrow-left"></i>
                                            </button>
                                            <button className="btn btn-outline-secondary px-3 py-2 ms-3" type="button" onClick={
                                                () => {handlePrint()}
                                            }>
                                                <i
                                                    className="fa-solid fa-print"
                                                ></i> Imprimir
                                            </button>
                                        </div>
                                    }
                                    <h3 className="page-title fw-bold mb-lg-0 mb-4">
                                        {formTitle}
                                    </h3>
                                </div>
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card bg-fondo-suave">
                                <div className="card-body p-4">
                                    <div
                                        className='d-none d-print-block mt-5'
                                    ></div>
                                    <form action="" ref={componentRef}>
                                        <div className="row">
                                            <div className="col-md-12 px-md-4 px-0">
                                                <div className="form-outline mb-4">
                                                    <h2>
                                                        {formData.applicationTitle}
                                                    </h2>
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <h5 className="form-label" htmlFor="">Descripción</h5>
                                                    <p>
                                                        {formData.applicationDescription}
                                                    </p>
                                                </div>
                                                <div className="form-outline mb-4">
                                                    <h5 className="form-label" htmlFor="">Fecha de Implementación</h5>
                                                    <p>
                                                        {
                                                            new Date(formData.applicationImplementationDate).toLocaleDateString('es-CL')
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 px-md-4 px-0">
                                                <div className="form-outline mb-4">
                                                    <h5 className="form-label" htmlFor="">Beneficios</h5>
                                                    <p>
                                                        {formData.applicationBenefits}
                                                    </p>
                                                </div>
                                                {/*}
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="">Antecedentes complementarios</label>
                                                    {
                                                        !readOnly && <div className="input-group mb-3">
                                                            <input
                                                                type="file"
                                                                className="form-control form-control-lg rounded-pill"
                                                                id="applicationFile"
                                                                name="applicationFile"
                                                                accept='.pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .gif'
                                                                value={``}
                                                                onChange={handleFileUpload}
                                                                disabled={readOnly}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                {*/}

                                                <div className="form-outline mb-4 d-print-none">
                                                    {
                                                        formData.files.length > 0 &&

                                                        formData.files.map((item, index) => {
                                                            return (
                                                                <div className="mb-3" key={index}>
                                                                    <div className='d-flex justify-content-start align-items-center'>
                                                                        {
                                                                            !readOnly && <a
                                                                                onClick={
                                                                                    () => handleFileDelete(item)
                                                                                }
                                                                                className='react-link btn btn-outline-danger me-4'
                                                                            >
                                                                                <i className="bi bi-trash"></i>
                                                                            </a>
                                                                        }
                                                                        <a
                                                                            onClick={
                                                                                () => handleFileDownload(item)
                                                                            }
                                                                            className="react-link"
                                                                        >
                                                                            {item.applicationFileName}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        )
                                                    }
                                                    {
                                                        formData.files.length === 0 && <div className="mb-3">
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                <strong className="react-link text-danger">No hay archivos adjuntos</strong>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            dynamicFormList.length > 0 &&
                                            <div className="row">
                                                {
                                                    dynamicFormList.map((categoryGroup, indexCG) => {
                                                        return (
                                                            <div className="col-md-6 px-md-4 px-0" key={indexCG}>
                                                                <div className="form-outline mb-2">
                                                                    <h5>Foco</h5>
                                                                    <p className="form-label" htmlFor=""> {categoryGroup.categoryGroupName}</p>
                                                                    <h5>Categoría</h5>
                                                                    <p className="form-label" htmlFor="">
                                                                        {
                                                                            formData.dynamic.find(
                                                                                dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                            )?.category?.categoryId &&
                                                                            ` ` + dynamicFormList[indexCG].categories.find(
                                                                                category => category.id === formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                )?.category?.categoryId
                                                                            ).categoryName
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {
                                                                    formData.dynamic.find(
                                                                        dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                    ).category?.categoryId &&
                                                                    <div className="form-outline mb-4">
                                                                        {
                                                                            dynamicFormList[indexCG].categories.find(
                                                                                category => category.id === formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                )?.category?.categoryId
                                                                            ).categoryType === 'multiple' &&
                                                                            dynamicFormList[indexCG].categories.find(
                                                                                category => category.id === formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                )?.category?.categoryId
                                                                            ).tags.map((tag, indexT) => {
                                                                                let isTag = formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                )?.category?.tags.includes(tag.id)
                                                                                let showTag = false
                                                                                if (isTag) {
                                                                                    showTag = <p key={indexT} className={
                                                                                        `mt-2 float-start`
                                                                                    }>
                                                                                        <span className="badge bg-secondary me-2">
                                                                                            {tag.tagName}
                                                                                        </span>
                                                                                    </p>
                                                                                }
                                                                                return showTag

                                                                            })
                                                                        }
                                                                        {
                                                                            dynamicFormList[indexCG].categories.find(
                                                                                category => category.id === formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                ).category?.categoryId
                                                                            ).categoryType === 'simple' &&
                                                                            dynamicFormList[indexCG].categories.find(
                                                                                category => category.id === formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                ).category?.categoryId
                                                                            ).tags.map((tag, indexT) => {
                                                                                let isTag = formData.dynamic.find(
                                                                                    dynamic => dynamic.categoryGroupId === categoryGroup.id
                                                                                )?.category?.tags.includes(tag.id)
                                                                                let showTag = false
                                                                                if (isTag) {
                                                                                    showTag = <h4 key={indexT} className={
                                                                                        `mt-2 float-start`
                                                                                    }>
                                                                                        <span className="badge bg-secondary me-2">
                                                                                            {tag.tagName}
                                                                                        </span>
                                                                                    </h4>
                                                                                }
                                                                                return showTag
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ModalComponent
                id='saveModal'
                title={saveModalTitle}
                show={showSaveModal}
                setShow={setShowSaveModal}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        La postulación ha sido guardada con éxito.
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowSaveModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='saveAndSendModal'
                title={saveAndSendModalTitle}
                show={showSaveAndSendModal}
                setShow={setShowSaveAndSendModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        La postulación ha sido guardada con éxito.
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowSaveAndSendModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='genericModal'
                title={genericModalTitle}
                show={showGenericModal}
                setShow={setShowGenericModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <p>
                        {genericModalMessage}
                    </p>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowGenericModal(false)
                        }}
                    >
                        Continuar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='genericActionModal'
                title={genericActionModalTitle}
                show={showGenericActionModal}
                setShow={setShowGenericActionModal}
            >
                <div className='d-flex justify-content-between mt-4'>
                    <textarea
                        className='form-control'
                        rows='3'
                        placeholder={genericActionModalMessage}
                        ref={textareaRef}
                    ></textarea>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    {genericActionModalButton}
                </div>
            </ModalComponent>
            <ModalComponent
                id='preOperationModal'
                title={preOperationModalTitle}
                show={showPreOperationModal}
                setShow={setShowPreOperationModal}
                headerClass='bg-primary text-white'
                modalSize='modal-lg'
            >
                <p>{preOperationModalMessage}</p>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowPreOperationModal(false)
                        }}
                    >
                        No
                    </button>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => {
                            setShowPreOperationModal(false)
                            handleFormSaveAndSend()
                        }}
                    >
                        Sí
                    </button>
                </div>
            </ModalComponent>
        </>
    )
}
