import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import Form from '../Ticket/Form/Index'
import sessionUtils from '../../../utilities/SessionHandler'

import contestService from '../../../services/contests'
import ticketService from '../../../services/tickets'
import ticketResponseService from '../../../services/ticketResponses'
import applicationService from '../../../services/applications'
import ticketFileService from '../../../services/ticketFiles'

export default function Edit() {

    let credentials = sessionUtils.getParsedCredentials()

    const navigateTo = useNavigate()
    const { ticket_id } = useParams()
    const [isLoading, setIsLoading] = useState(true)

    const [contestData, setContestData] = useState(null)
    const [itemData, setItemData] = useState(null)

    const [selectedItemId, setSelectedItemId] = useState(null)

    const handleDataLoad = async () => {
        let newResult = null;
        try {
            const result = await ticketService.get(ticket_id)
            newResult = {
                ...result,
            }
            const responseResult = await ticketResponseService.byTicket(ticket_id)
            newResult = {
                ...newResult,
                ticketResponses: responseResult
            }

            const filesResult = await ticketFileService.byTicket(ticket_id)

            newResult = {
                ...newResult,
                files: [
                    ...filesResult
                ],
                user_id: credentials.id
            }
            
        } catch (error) {
        }
        setItemData(newResult)
    }

    const handleContestLoad = async () => {
        try {
            const result = await contestService.showActive()
            setContestData(result)
        } catch (error) {
            setContestData(false)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        handleContestLoad()
        handleDataLoad()
    }, [])

    return (
        <>
            <Navbar />
            {
                isLoading &&
                <>
                    <div className='vh-100 d-flex justify-content-center align-items-center'>
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </>
            }
            {
                itemData && contestData && <Form
                    contestData={contestData}
                    formTitle={
                        itemData.ticketStatus === 'en revisión' ? 'Revisar ticket' : 'Editar ticket'
                    }
                    backRoute={`/tickets`}
                    itemData={itemData}
                />
            }
        </>
    )
}
