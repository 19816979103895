import React, { useEffect, useState } from 'react'

export default function Card(props) {

    const [data, setData] = useState(null)

    useEffect(() => {
        if (props.loading) {
            //show spinner
            setData(<h4>
                <div className="spinner-border py-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </h4>)
        } else {
            //hide spinner
            setData(<h4 className="h2 fw-medium">{props.title}</h4>)
        }
    }, [props])


    return (
        <div className={`card rounded no-shadow border-0 ${props.classType}`}>
            <div className="card-body">
                {data}
                <span>{props.text}</span>
            </div>
        </div>
    )
}
