import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Card(props) {

    const navigateTo = useNavigate()

    return (
        <div className="card rounded no-shadow py-3">
            <div className="card-body">
                {props.icon}
                <h4 className="h5 fw-medium my-4">
                    {props.title}
                </h4>
                <button className="btn btn-primary px-3 py-2 mb-3" type="button" onClick={
                    () => navigateTo(props.route)
                }>
                    Ver más
                </button>
            </div>
        </div>
    )
}
