import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import contestService from '../../../services/contests'
import categoryGroupService from '../../../services/categorygroups'
import applicationService from '../../../services/applications'

import * as XLSX from 'xlsx'

export default function List() {

  const navigateTo = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)
  const [addModalTitle, setAddModalTitle] = useState('Crear concurso')
  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('Editar concurso')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar concurso')
  const [currentActivityAction, setCurrentActivityAction] = useState('')
  const [showActionModal, setShowActionModal] = useState(false)
  const [actionModalTitle, setActionModalTitle] = useState('Pausar/Reanudar concurso')

  const [contestStatusList, setContestStatusList] = useState([
    {
      value: 'creado',
      text: 'Creado',
    },
    {
      value: 'activo',
      text: 'Activo',
    },
    {
      value: 'inactivo',
      text: 'Inactivo',
    },
  ])

  const [applicationListToDownload, setApplicationListToDownload] = useState(null)

  const [listData, setListData] = useState(null)
  const [categoryGroupsInContest, setCategoryGroupsInContest] = useState([])

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [formData, setFormData] = useState({
    contestName: '',
    contestDescription: '',
    contestStatus: 'creado',
    contestClosingDateTime: '',
    contestCategoryInGroup_id: '',
  })

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  const handleDownloadApplicationsAsExcel = async (contestId) => {

    const contestResult = await contestService.get(contestId)
    const result = await applicationService.byContest(contestId)

    let stages = [
      'finalistas',
      'destacadas',
    ];

    let filteredPerStage;
    let filteredPerStageResult = {};

    stages.map((stage, index) => {
      filteredPerStage = result;

      switch (stage) {
        case 'finalistas':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusAfterDraft === 'Aprobada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusPreselection === 'Seleccionada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => ['En revisión', 'No seleccionada'].includes(item.applicationStatusFinalist)
          )
          break;
        case 'destacadas':
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusDraft === 'Enviada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusAfterDraft === 'Aprobada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusPreselection === 'Seleccionada'
          )
          filteredPerStage = filteredPerStage.filter(
            item => item.applicationStatusFinalist === 'Seleccionada'
          )
          break;
        default:
          filteredPerStage = [];
      }

      filteredPerStageResult[stage] = filteredPerStage;

    })

    var localUrl = window.location.protocol + '//' + window.location.host;

    const dataFinalistas = filteredPerStageResult.finalistas.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Descripción': item.applicationDescription,
        'Beneficios': item.applicationBenefits,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusFinalist,
        'Enlace': localUrl + '/good-practices/' + item.id,
      }
    })

    const dataDestacadas = filteredPerStageResult.destacadas.map((item, index) => {
      return {
        'Título de la Postulación': item.applicationTitle,
        'Descripción': item.applicationDescription,
        'Beneficios': item.applicationBenefits,
        'Categoría': item.category?.categoryName || "-",
        'Empresa': item.company?.companyName,
        'Agente': item.user?.userFullName || "No asignado",
        'Enviado': item.applicationStatusEditedByCompanyDateTime,
        'Estado': item.applicationStatusFinal,
        'Enlace': localUrl + '/good-practices/' + item.id,
      }
    })

    //one per page

    const data = [
      {
        name: 'Finalistas',
        data: dataFinalistas
      },
      {
        name: 'Destacadas',
        data: dataDestacadas
      },
    ]

    const contestYear = new Date(contestResult.contestClosingDateTime).getFullYear()
    const fileName = 'postulaciones_' + contestYear + '.xlsx'

    const workbook = XLSX.utils.book_new()
    data.map((item, index) => {
      const worksheet = XLSX.utils.json_to_sheet(item.data)
      XLSX.utils.book_append_sheet(workbook, worksheet, item.name)
    })
    XLSX.writeFile(workbook, fileName)

  }

  useEffect(() => {
  }, [formData])

  const getFormDOM = (
    {
      action,
    }
  ) => {
    return <>
      <div className='mb-3'>
        <label htmlFor='contestName' className='form-label'>Nombre del concurso</label>
        <input
          name='contestName'
          id='contestName'
          type='text'
          className='form-control'
          placeholder='Nombre del concurso'
          value={formData.contestName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='contestDescription' className='form-label'>Descripción del concurso</label>
        <textarea
          name='contestDescription'
          id='contestDescription'
          className='form-control'
          placeholder='Descripción del concurso'
          value={formData.contestDescription}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='contestClosingDateTime' className='form-label'>Fecha de cierre del concurso</label>
        <input
          name='contestClosingDateTime'
          id='contestClosingDateTime'
          type='date'
          className='form-control'
          placeholder='Fecha de cierre del concurso'
          value={formData.contestClosingDateTime}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      {
        action === 'edit' &&
        <>
          {
            categoryGroupsInContest
              .filter((categoryGroup) => categoryGroup.contest_id === selectedItemId)
              .length > 0 && <div className='mb-3'>
              <label htmlFor='contestCategoryInGroup_id' className='form-label'>Categoría del concurso</label>
              <select
                name='contestCategoryInGroup_id'
                id='contestCategoryInGroup_id'
                className='form-select'
                value={formData.contestCategoryInGroup_id}
                onChange={
                  (event) => {
                    handleFieldChange(event)
                  }
                }
              >
                <option value=''>Seleccione una categoría</option>
                {

                  categoryGroupsInContest
                    .filter((categoryGroup) => categoryGroup.contest_id === selectedItemId)
                    .map((categoryGroup, index) => {
                      return <option key={index} value={categoryGroup.id}>{categoryGroup.categoryGroupName}</option>
                    })
                }
              </select>
            </div>
          }
          {
            categoryGroupsInContest
              .filter((categoryGroup) => categoryGroup.contest_id === selectedItemId)
              .length === 0 && <>
              <input type='hidden' name='contestCategoryInGroup_id' value='' />
            </>
          }
        </>
      }
      {
        action === 'create' &&
        <>
          <input type='hidden' name='contestCategoryInGroup_id' value='' />
        </>
      }
    </>
  }

  const handleDataCreate = async () => {
    try {
      await contestService.create(formData)
      await handleDataLoad()
      setShowAddModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataUpdate = async (itemId) => {
    try {

      let newFormData = {
        ...formData,
        contestCategoryInGroup_id: formData.contestCategoryInGroup_id === '' ? null : formData.contestCategoryInGroup_id,
      }

      await contestService.update(itemId, newFormData)
      await handleDataLoad()
      setShowEditModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataDelete = async (itemId) => {
    await contestService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataLoad = async () => {
    const categoryGroupsResult = await categoryGroupService.list()

    const result = await contestService.list()
    setCategoryGroupsInContest(categoryGroupsResult)
    setListData(result)
  }

  useEffect(() => {
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar />
      {
        listData && <ListSection
          title='Concursos'
          hasSearch={true}
          hasYear={false}
          tabs={false}
          pagination={
            {
              itemsPerPage: 5,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/home')
              }
            }
          }
          actions={
            {
              title: 'Crear concurso',
              onClick: () => {
                setFormData(
                  {
                    ...formData,
                    contestName: '',
                    contestDescription: '',
                    contestStatus: 'creado',
                    contestClosingDateTime: '',
                    contestCategoryInGroup_id: null,
                  }
                )
                setShowAddModal(true)
              }
            }
          }
          columns={
            [
              {
                title: 'Nombre',
                field: 'contestName',
                type: 'text',
              },
              {
                title: 'Descripción',
                field: 'contestDescription',
                type: 'text',
              },
              {
                title: 'Fecha de cierre',
                field: 'contestClosingDateTime',
                type: 'date',
              },
              {
                title: 'Estado',
                field: 'contestStatus',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []
                if (item.contestStatus === 'creado') {

                  actions = [
                    {
                      className: 'btn',
                      icon: 'bi bi-files',
                      tooltip: 'Gestionar archivos de concurso',
                      onClick: () => {
                        navigateTo('/contestfiles/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-hexagon',
                      tooltip: 'Gestionar grupos/criterios de categoría',
                      onClick: () => {
                        navigateTo('/categorygroups/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-pencil',
                      tooltip: 'Editar',
                      onClick: () => {
                        setFormData({
                          contestName: item.contestName,
                          contestDescription: item.contestDescription,
                          contestStatus: item.contestStatus,
                          contestClosingDateTime: item.contestClosingDateTime || '',
                          contestCategoryInGroup_id: item.contestCategoryInGroup_id || '',
                        })
                        setSelectedItemId(item.id)
                        setEditModalTitle('Editar ' + item.contestName)
                        setShowEditModal(true)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Borrar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Borrar ' + item.contestName)
                        setShowDeleteModal(true)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-check',
                      tooltip: 'Activar concurso',
                      onClick: async () => {
                        await contestService.activate(item.id)
                        handleDataLoad()
                      }
                    }
                  ]

                } else if (item.contestStatus === 'activo') {

                  actions = [
                    {
                      className: 'btn',
                      icon: 'bi bi-files',
                      tooltip: 'Gestionar archivos de concurso',
                      onClick: () => {
                        navigateTo('/contestfiles/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-pencil',
                      tooltip: 'Editar',
                      onClick: () => {
                        setFormData({
                          contestName: item.contestName,
                          contestDescription: item.contestDescription,
                          contestStatus: item.contestStatus,
                          contestClosingDateTime: item.contestClosingDateTime || '',
                          contestCategoryInGroup_id: item.contestCategoryInGroup_id || '',
                        })
                        setSelectedItemId(item.id)
                        setEditModalTitle('Editar ' + item.contestName)
                        setShowEditModal(true)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-eye',
                      tooltip: 'Descargar postulaciones',
                      onClick: async () => {
                        handleDownloadApplicationsAsExcel(item.id)
                      }
                    },
                  ]

                  if(item.contestActivity === 'Online'){
                    actions.push(
                      {
                        className: 'btn',
                        icon: 'bi bi-pause-circle',
                        tooltip: 'Pausar',
                        onClick: async () => {
                          console.log('pausar')
                          setCurrentActivityAction('Pausa')
                          setSelectedItemId(item.id)
                          setActionModalTitle('Pausar concurso')
                          setShowActionModal(true)
                          handleDataLoad()
                        }
                      }
                    )
                  }
                  else {
                    actions.push(
                      {
                        className: 'btn',
                        icon: 'bi bi-play-circle',
                        tooltip: 'Reanudar',
                        onClick: async () => {
                          console.log('reanudar')
                          setCurrentActivityAction('Reanudar')
                          setSelectedItemId(item.id)
                          setActionModalTitle('Reanudar concurso')
                          setShowActionModal(true)
                          handleDataLoad()
                        }
                      }
                    )
                  }

                } else {

                  actions = [

                    {
                      className: 'btn',
                      icon: 'bi bi-files',
                      tooltip: 'Gestionar archivos de concurso',
                      onClick: () => {
                        navigateTo('/contestfiles/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-eye',
                      tooltip: 'Descargar postulaciones',
                      onClick: async () => {
                        handleDownloadApplicationsAsExcel(item.id)
                      }
                    }

                  ]

                }

                let appSentDate = item.contestClosingDateTime;
                if (appSentDate) {
                  appSentDate = new Date(appSentDate)
                  //set UTC
                  appSentDate = new Date(appSentDate.getTime() + appSentDate.getTimezoneOffset() * 60000)
                  appSentDate = appSentDate.toLocaleDateString('es-CL')
                } else {
                  appSentDate = '-'
                }
                appSentDate = {
                  text: appSentDate,
                  className: 'align-middle'
                }



                return {
                  contestName: item.contestName,
                  contestDescription: item.contestDescription,
                  contestStatus: contestStatusList.find((status) => status.value === item.contestStatus).text,
                  contestClosingDateTime: appSentDate,
                  actions: actions,
                }
              })
            ]
          }
        />
      }
      <ModalComponent
        id='createModal'
        title={addModalTitle}
        show={showAddModal}
        setShow={setShowAddModal}
      >
        {getFormDOM(
          {
            action: 'create',
          }
        )}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowAddModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataCreate()
            }}
          >
            Crear
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='editModal'
        title={editModalTitle}
        show={showEditModal}
        setShow={setShowEditModal}
      >
        {getFormDOM(
          {
            action: 'edit',
          }
        )}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowEditModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataUpdate(selectedItemId)
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar este concurso?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='actionModal'
        title={actionModalTitle}
        show={showActionModal}
        setShow={setShowActionModal}
      >
        {
          currentActivityAction === 'Pausa' &&
          <p>¿Está seguro que desea pausar este concurso?</p>
        }
        {
          currentActivityAction === 'Reanudar' &&
          <p>¿Está seguro que desea reanudar este concurso?</p>
        }
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowActionModal(false)
              setSelectedItemId(null)
              setCurrentActivityAction('')
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={async () =>  {
              let item = listData.find(item => item.id === selectedItemId)
              await contestService.update(item.id, 
                { 
                  ...item,
                  contestActivity: currentActivityAction === 'Pausa' ? 'Pausa' : 'Online'
                }
              )
              handleDataLoad()
              setShowActionModal(false)
              setSelectedItemId(null)
              setCurrentActivityAction('')
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
