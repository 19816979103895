import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import contestService from '../../../services/contests'
import categorygroupService from '../../../services/categorygroups'

export default function List() {

  const navigateTo = useNavigate()
  const params = useParams()
  const [parentData, setParentData] = useState({
    id: params.contest_id,
    contestName: '',
  })

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)
  const [addModalTitle, setAddModalTitle] = useState('Crear grupo')
  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('Editar grupo')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar grupo')

  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [formData, setFormData] = useState({
    contest_id: params.contest_id,
    categoryGroupName: '',
  })

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  useEffect(() => {
  }, [formData])

  const getFormDOM = () => {
    return <>
      <div className='mb-3'>
        <label htmlFor='categoryGroupName' className='form-label'>Nombre del grupo</label>
        <input
          name='categoryGroupName'
          id='categoryGroupName'
          type='text'
          className='form-control'
          placeholder='Nombre del grupo'
          value={formData.categoryGroupName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
    </>
  }

  const handleDataCreate = async () => {
    try {
      await categorygroupService.create(formData)
      await handleDataLoad()
      setShowAddModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataUpdate = async (itemId) => {
    try {
      await categorygroupService.update(itemId, formData)
      await handleDataLoad()
      setShowEditModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }


  const handleDataDelete = async (itemId) => {
    await categorygroupService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataLoad = async () => {
    const contest = await contestService.get(params.contest_id)

    setParentData({
      ...parentData,
      contestName: contest.contestName,
    })

    const result = await categorygroupService.byContest(params.contest_id)
    setListData(result)
  }

  useEffect(() => {
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar />
      {
        listData &&
        <ListSection
          pagination={
            {
              itemsPerPage: 5,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
          title={`Grupos de ${parentData.contestName}`}
          hasSearch={true}
          hasYear={false}
          tabs={false}
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/contests')
              }
            }
          }
          actions={
            {
              title: 'Crear grupo',
              onClick: () => {
                setFormData({
                  ...formData,
                  categoryGroupName: '',
                })
                setShowAddModal(true)
              }
            }
          }
          columns={
            [
              {
                title: 'Nombre',
                field: 'categoryGroupName',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {
                return {
                  categoryGroupName: item.categoryGroupName,
                  actions: [
                    {
                      className: 'btn',
                      icon: 'bi bi-tag',
                      tooltip: 'Gestionar categorías',
                      onClick: () => {
                        navigateTo('/categories/' + item.id)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-pencil',
                      tooltip: 'Editar',
                      onClick: () => {
                        setFormData({
                          ...formData,
                          categoryGroupName: item.categoryGroupName,
                        })
                        setSelectedItemId(item.id)
                        setEditModalTitle('Editar ' + item.categoryGroupName)
                        setShowEditModal(true)
                      }
                    },
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Borrar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Borrar ' + item.categoryGroupName)
                        setShowDeleteModal(true)
                      }
                    }
                  ],
                }
              })
            ]
          }
        />
      }
      <ModalComponent
        id='createModal'
        title={addModalTitle}
        show={showAddModal}
        setShow={setShowAddModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowAddModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataCreate()
            }}
          >
            Crear
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='editModal'
        title={editModalTitle}
        show={showEditModal}
        setShow={setShowEditModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowEditModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataUpdate(selectedItemId)
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar este grupo?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
