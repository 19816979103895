import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import contestService from '../../../services/contests'
import fileService from '../../../services/files'
import contestFileService from '../../../services/contestFiles'

export default function List() {

    const navigateTo = useNavigate()
    const params = useParams()

    const [parentData, setParentData] = useState({
        id: params.contest_id,
        contestName: '',
        contestStatus: 'creado',
    })

    const [showAddModal, setShowAddModal] = useState(false)
    const [addModalTitle, setAddModalTitle] = useState('Crear archivo')
    const [showEditModal, setShowEditModal] = useState(false)
    const [editModalTitle, setEditModalTitle] = useState('Editar archivo')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar archivo')

    const [listData, setListData] = useState(null)

    const [selectedItemId, setSelectedItemId] = useState(null)

    const [formData, setFormData] = useState({
        contest_id: params.contest_id,
        contestFileName: '',
        file: '',
    })

    const handleFieldChange = (event) => {
        setFormData(
            {
                ...formData,
                [event.target.name]: event.target.value
            }
        )
    }

    useEffect(() => {
    }, [formData])

    const getFormDOM = () => {
        return <>
            {
                !selectedItemId && <div className='mb-3'>
                    <label htmlFor='file' className='form-label'>Archivo</label>
                    <input
                        name='file'
                        id='file'
                        type='file'
                        className='form-control'
                        placeholder='File'
                        onChange={
                            (e) => {
                                setFormData({
                                    ...formData,
                                    file: e.target.files[0],
                                })
                            }
                        }
                    />
                </div>
            }
            <div className='mb-3'>
                <label htmlFor='contestFileName' className='form-label'>Nombre del archivo</label>
                <input
                    name='contestFileName'
                    id='contestFileName'
                    type='text'
                    className='form-control'
                    placeholder='Nombre del archivo'
                    value={formData.contestFileName}
                    onChange={handleFieldChange}
                />
            </div>
        </>
    }

    const handleDataCreate = async () => {
        const fileResult = await fileService.create(formData)
        await contestFileService.create(
            {
                contest_id: params.contest_id,
                file_id: fileResult.id,
                contestFileName: formData.contestFileName,
            }
        );
        await handleDataLoad()
    }

    const handleDataUpdate = async (itemId) => {
        await contestFileService.update(itemId, formData)
        await handleDataLoad()
    }

    const handleDataDelete = async (itemId) => {
        await contestFileService.remove(itemId)
        await handleDataLoad()
    }

    const handleDataLoad = async () => {
        const contest = await contestService.get(params.contest_id)

        setParentData({
            ...parentData,
            contestName: contest.contestName,
            contestStatus: contest.contestStatus,
        })

        setFormData({
            contest_id: params.contest_id,
            contestFileName: '',
            file: '',
        })

        const result = await contestFileService.byContest(params.contest_id)
        setListData(result)
    }

    const handleFileDownload = async (item) => {
        const fileResult = await fileService.get(item.file_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        const downloadLink = document.createElement("a")
        const fileName = item.contestFileName
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    useEffect(() => {
        //console.log(listData)
    }, [listData])

    useEffect(() => {
        handleDataLoad()
    }, [])

    return (
        <>
            <Navbar />
            {
                listData &&
                <ListSection
                    title={`Archivos de ${parentData.contestName}`}
                    hasSearch={false}
                    hasYear={false}
                    tabs={false}
                    backRoute={
                        {
                            title: 'Volver',
                            onClick: () => {
                                navigateTo('/contests')
                            }
                        }
                    }
                    actions={
                        parentData.contestStatus !== 'inactivo' && {
                            title: 'Subir archivo',
                            onClick: () => {
                                setFormData({
                                    ...formData,
                                    file: '',
                                    contestFileName: '',
                                })
                                setShowAddModal(true)
                            }
                        }
                    }
                    columns={
                        [
                            {
                                title: 'Nombre del archivo',
                                field: 'contestFileName',
                                type: 'text',
                            },
                            {
                                title: 'Acciones',
                                field: 'actions',
                                type: 'actions',
                            },
                        ]
                    }
                    rows={
                        listData?.map((item, index) => {

                            let actions = []

                            if (item.contest.contestStatus === 'creado') {
                                actions = [
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-file-earmark-text',
                                        tooltip: 'Descargar',
                                        onClick: () => {
                                            handleFileDownload(item)
                                        }
                                    },
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-pencil',
                                        tooltip: 'Editar',
                                        onClick: () => {
                                            setSelectedItemId(item.id)
                                            setEditModalTitle('Editar archivo')
                                            setFormData({
                                                ...formData,
                                                contestFileName: item.contestFileName,
                                            })
                                            setShowEditModal(true)
                                        }
                                    },
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-trash',
                                        tooltip: 'Borrar',
                                        onClick: () => {
                                            setSelectedItemId(item.id)
                                            setDeleteModalTitle('Borrar archivo')
                                            setShowDeleteModal(true)
                                        }
                                    },
                                ]
                            } else if(item.contest.contestStatus === 'activo') {
                                actions = [
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-file-earmark-text',
                                        tooltip: 'Descargar',
                                        onClick: () => {
                                            handleFileDownload(item)
                                        }
                                    },
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-pencil',
                                        tooltip: 'Editar',
                                        onClick: () => {
                                            setSelectedItemId(item.id)
                                            setEditModalTitle('Editar archivo')
                                            setFormData({
                                                ...formData,
                                                contestFileName: item.contestFileName,
                                            })
                                            setShowEditModal(true)
                                        }
                                    },
                                ]
                            } else {
                                actions = [
                                    {
                                        className: 'btn',
                                        icon: 'bi bi-file-earmark-text',
                                        tooltip: 'Descargar',
                                        onClick: () => {
                                            handleFileDownload(item)
                                        }
                                    },
                                ]
                            }

                            return {
                                contestFileName: item.contestFileName,
                                actions: actions,
                            }
                        })
                    }
                />
            }
            <ModalComponent
                id='createModal'
                title={addModalTitle}
                show={showAddModal}
                setShow={setShowAddModal}
            >
                {getFormDOM()}
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowAddModal(false)
                            setSelectedItemId(null)
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                            setShowAddModal(false)
                            handleDataCreate()
                            setSelectedItemId(null)
                        }}
                    >
                        Crear
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='editModal'
                title={editModalTitle}
                show={showEditModal}
                setShow={setShowEditModal}
            >
                {getFormDOM()}
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowEditModal(false)
                            setSelectedItemId(null)
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                            handleDataUpdate(selectedItemId)
                            setShowEditModal(false)
                            setSelectedItemId(null)
                        }}
                    >
                        Guardar
                    </button>
                </div>
            </ModalComponent>
            <ModalComponent
                id='deleteModal'
                title={deleteModalTitle}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
            >
                <p>¿Está seguro que desea borrar este grupo?</p>
                <div className='d-flex justify-content-between mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowDeleteModal(false)
                            setSelectedItemId(null)
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => {
                            handleDataDelete(selectedItemId)
                            setShowDeleteModal(false)
                            setSelectedItemId(null)
                        }}
                    >
                        Borrar
                    </button>
                </div>
            </ModalComponent>
        </>
    )
}
