import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../../components/Private/Navbar'
import ModalComponent from '../../../components/Private/ModalComponent'
import Form from '../Application/Form/Index'
import sessionHandler from '../../../utilities/SessionHandler'

import ticketService from '../../../services/tickets'

export default function Create() {

    const navigateTo = useNavigate()

    const [contestData, setContestData] = useState(null)
    const [itemData, setItemData] = useState(null)

    const [selectedItemId, setSelectedItemId] = useState(null)
    const credentials = sessionHandler.getParsedCredentials()

    const handleDataLoad = async () => {

        try {

            let newTicket = {
                company_id: credentials.company_id,
                companyUser_id: credentials.id,
                ticketTitle: '',
                ticketDescription: '',
                ticketStatus: 'borrador',
            };

            const result = await ticketService.create(newTicket)

            console.log(result)
            navigateTo(`/ticket/edit/${result.id}`)
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        handleDataLoad()
    }, [])

    return <div className='vh-100 d-flex justify-content-center align-items-center'>
        <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
}
