import React, { useState, useEffect } from 'react'
import sessionHandler from '../../../utilities/SessionHandler'

import { useNavigate } from 'react-router-dom'

import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import stateService from '../../../services/states'
import cityService from '../../../services/cities'
import companyService from '../../../services/companies'

import * as XLSX from 'xlsx'

export default function List() {

  const navigateTo = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showAddModal, setShowAddModal] = useState(false)
  const [addModalTitle, setAddModalTitle] = useState('Crear empresa')
  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('Editar empresa')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar empresa')

  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const formDefaultData = {
    state_id: '',
    city_id: '',
    companyName: '',
    companyDNI: '',
    companyAddress: '',
    companyManagerName: '',
    companyManagerEmail: '',
    companyManagerPhone: '',
  }

  const [formData, setFormData] = useState(formDefaultData)

  const credentials = sessionHandler.getParsedCredentials();

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  useEffect(() => {
  }, [formData])

  const getFormDOM = () => {
    return <>
      <div className='mb-3'>
        <label htmlFor='companyName' className='form-label'>Nombre</label>
        <input
          name='companyName'
          id='companyName'
          type='text'
          className='form-control'
          placeholder='Nombre'
          value={formData.companyName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyDNI' className='form-label'>RUT</label>
        <input
          name='companyDNI'
          id='companyDNI'
          type='text'
          className='form-control'
          placeholder='RUT'
          value={formData.companyDNI}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyAddress' className='form-label'>Dirección</label>
        <input
          name='companyAddress'
          id='companyAddress'
          type='text'
          className='form-control'
          placeholder='Dirección'
          value={formData.companyAddress}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='state_id' className='form-label'>Región</label>
        <select
          name='state_id'
          id='state_id'
          className='form-select'
          value={formData.state_id}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        >
          <option value=''>Seleccionar región</option>
          {
            stateList.map((item, index) => {
              return <option key={index} value={item.id}>{item.stateName}</option>
            })
          }
        </select>
      </div>
      {
        formData.state_id !== '' &&
        (
          <div className='mb-3'>
            <label htmlFor='city_id' className='form-label'>Comuna</label>
            <select
              name='city_id'
              id='city_id'
              className='form-select'
              value={formData.city_id}
              onChange={
                (event) => {
                  handleFieldChange(event)
                }
              }
            >
              <option value=''>Seleccionar comuna</option>
              {
                cityList
                  .filter(
                    (item) => {
                      return item.state_id === formData.state_id
                    }
                  )
                  .map((item, index) => {
                    return <option key={index} value={item.id}>{item.cityName}</option>
                  })
              }
            </select>
          </div>
        )
      }
      <div className='mb-3'>
        <label htmlFor='companyManagerName' className='form-label'>Nombre del encargado</label>
        <input
          name='companyManagerName'
          id='companyManagerName'
          type='text'
          className='form-control'
          placeholder='Nombre del encargado'
          value={formData.companyManagerName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyManagerEmail' className='form-label'>Email del encargado</label>
        <input
          name='companyManagerEmail'
          id='companyManagerEmail'
          type='text'
          className='form-control'
          placeholder='Email del encargado'
          value={formData.companyManagerEmail}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyManagerPhone' className='form-label'>Teléfono del encargado</label>
        <input
          name='companyManagerPhone'
          id='companyManagerPhone'
          type='text'
          className='form-control'
          placeholder='Teléfono del encargado'
          value={formData.companyManagerPhone}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
        />
      </div>
    </>
  }

  const handleDataCreate = async () => {
    try {
      await companyService.create(formData)
      await handleDataLoad()
      setShowAddModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataUpdate = async (itemId) => {
    try {
      await companyService.update(itemId, formData)
      await handleDataLoad()
      setShowEditModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataDelete = async (itemId) => {
    await companyService.remove(itemId)
    await handleDataLoad()
  }

  const handleStateListDataLoad = async () => {
    const result = await stateService.list()
    setStateList(result)
  }

  const handleCityListDataLoad = async () => {
    const result = await cityService.list()
    setCityList(result)
  }

  const handleDataLoad = async () => {
    const result = await companyService.list()
    setListData(result)
  }

  const handleDownloadListAsExcel = () => {
    const data = listData.map((item, index) => {
      return {
        'Nombre': item.companyName,
        'RUT': item.companyDNI,
        'Nombre Encargado': item.companyManagerName,
        'Email Encargado': item.companyManagerEmail,
        'Teléfono Encargado': item.companyManagerPhone,
        'Dirección': item.companyAddress,
        'Región': stateList.find(state => state.id === item.state_id).stateName,
        'Comuna': cityList.find(city => city.id === item.city_id).cityName,
      }
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Empresas')
    XLSX.writeFile(workbook, 'Empresas.xlsx')
  }

  useEffect(() => {
    handleStateListDataLoad()
    handleCityListDataLoad()
    handleDataLoad()
  }, [])

  return (
    <>
      <Navbar />
      {
        listData != null
        && stateList?.length > 0
        && cityList?.length > 0
        &&
        <ListSection
          title='Empresas'
          hasSearch={true}
          hasYear={false}
          tabs={false}
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/home')
              }
            }
          }
          actions={
            [
              {
                title: 'Crear empresa',
                onClick: () => {
                  setFormData(formDefaultData)
                  setShowAddModal(true)
                }
              },
              {
                title: 'Descargar como Excel',
                onClick: () => {
                  handleDownloadListAsExcel()
                },
                className: 'btn-success'
              }
            ]
          }
          columns={
            [
              {
                title: 'Nombre',
                field: 'companyName',
                type: 'text',
              },
              {
                title: 'RUT',
                field: 'companyDNI',
                type: 'text',
              },
              {
                title: 'Región',
                field: 'state_id',
                type: 'text',
              },
              {
                title: 'Comuna',
                field: 'city_id',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []
                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-person-gear',
                    tooltip: 'Gestionar usuarios',
                    onClick: () => {
                      navigateTo('/companyusers/' + item.id)
                    }
                  }
                )
                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-file-medical',
                    tooltip: 'Postulaciones',
                    onClick: () => {
                      navigateTo('/applications/enviadas/' + item.id)
                    }
                  }
                )
                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-pencil',
                    tooltip: 'Editar',
                    onClick: () => {
                      setFormData({
                        state_id: item.state_id,
                        city_id: item.city_id,
                        companyName: item.companyName,
                        companyDNI: item.companyDNI,
                        companyAddress: item.companyAddress,
                        companyManagerName: item.companyManagerName,
                        companyManagerEmail: item.companyManagerEmail,
                        companyManagerPhone: item.companyManagerPhone,
                      })
                      setSelectedItemId(item.id)
                      setEditModalTitle('Editar a ' + item.companyName)
                      setShowEditModal(true)
                    }
                  }
                )
                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-trash',
                    tooltip: 'Eliminar',
                    onClick: () => {
                      setSelectedItemId(item.id)
                      setDeleteModalTitle('Eliminar a ' + item.companyName)
                      setShowDeleteModal(true)
                    }
                  }
                )
                return {
                  companyName: item.companyName,
                  companyDNI: item.companyDNI,
                  state_id: stateList.find(state => state.id === item.state_id).stateName,
                  city_id: cityList.find(city => city.id === item.city_id).cityName,
                  actions: [...actions],
                }
              })
            ]
          }
          pagination={
            {
              itemsPerPage: 10,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
        />
      }
      <ModalComponent
        id='createModal'
        title={addModalTitle}
        show={showAddModal}
        setShow={setShowAddModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowAddModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataCreate()
            }}
          >
            Crear
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='editModal'
        title={editModalTitle}
        show={showEditModal}
        setShow={setShowEditModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowEditModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataUpdate(selectedItemId)
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar esta empresa?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
