import React, { useRef, useEffect, useState } from 'react'
import { Offcanvas } from 'bootstrap'
import { useNavigate } from 'react-router-dom';
import sessionHandler from '../../utilities/SessionHandler'
import IMG_USER_AVATAR from '../../assets/img/dashboard/user-avatar.svg'
import sessionUtils from '../../utilities/SessionHandler'
import ModalComponent from '../Private/ModalComponent'
import fileService from '../../services/files'

export default function OffcanvasComponent(props) {

    const {
        show,
        setShow,
    } = props

    const [callbackData, setCallbackData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalBody, setModalBody] = useState('')
    const [userAvatar, setUserAvatar] = React.useState(null)

    const credentials = sessionHandler.getParsedCredentials()

    const navigateTo = useNavigate();

    useEffect(() => {
        if (!credentials) {
            sessionUtils.removeCredentials()
            
            setModalTitle('Sesión expirada')
            setModalBody('Tu sesión ha expirado, por favor inicia sesión nuevamente')
            setShowModal(true)

            setCallbackData({ destination: "/sign-in" })

            //navigateTo('/sign-in')
        }
    }, [])

    const handleCallbackOnHide = (data) => {
        navigateTo(data.destination)
    }

    const handleHome = () => {
        setShow(false)
        setTimeout(() => {
            navigateTo('/home')
        }, 100);
    }

    const handleSignOut = () => {
        sessionUtils.removeCredentials()
        setShow(false)
        setTimeout(() => {
            navigateTo('/')
        }, 100);
    }

    const handleProfile = () => {
        setShow(false)
        setTimeout(() => {
            navigateTo('/my-profile')
        }, 100);
    }

    const offcanvasRef = useRef(null)

    const showOffcanvas = () => {
        const offcanvasElement = offcanvasRef.current
        const offcanvas = new Offcanvas(offcanvasElement)
        offcanvas.show()
    }

    const hideOffcanvas = () => {
        const offcanvasElement = offcanvasRef.current
        const offcanvas = Offcanvas.getInstance(offcanvasElement)
        offcanvas.hide()
    }

    const handleFileImage = async (item) => {
        const fileResult = await fileService.get(item.avatar_id)
        if (!fileResult || !fileResult.fileBase64) return alert(
            fileResult.fileErrorMessage
        )
        const linkSource = `data:${fileResult.fileMimeType};base64,${fileResult.fileBase64}`
        setUserAvatar(linkSource)
    }

    useEffect(() => {
        credentials?.avatar_id && handleFileImage(credentials)
        const offcanvasElement = offcanvasRef.current
        const offcanvas = new Offcanvas(offcanvasElement)

        // if click outside offcanvas, hide it

        offcanvasElement.addEventListener('hidden.bs.offcanvas', function (event) {
            setShow(false)
        })

        return () => {
            offcanvas.dispose()
        }
    }, [])

    useEffect(() => {
        if (show) {
            showOffcanvas()
        } else {
            hideOffcanvas()
        }
    }, [show])

    return (

        <>
            <div
                ref={offcanvasRef}
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="menuDesplegable"
                aria-labelledby="menuDesplegableLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="menuDesplegableLabel"></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                        onClick={() => setShow(false)}
                    ></button>
                </div>
                <div className="offcanvas-body px-4">
                    <div className="mb-4">
                        <img
                            src={
                                credentials && credentials.avatar_id ?
                                    userAvatar :
                                    IMG_USER_AVATAR
                            }
                            alt=""
                            width="44"
                            height="44"
                            className="me-3 rounded-circle" />
                        {credentials?.userFullName || credentials?.companyUserFullName}
                    </div>
                    <ul className="navbar-nav justify-content-end flex-grow-1">
                        <li className="nav-item">
                            <a
                                className="nav-link active react-link"
                                aria-current="page"
                                onClick={handleHome}
                            >
                                Inicio
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link react-link"
                                onClick={handleProfile}
                            >
                                Mi perfil
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="offcanvas-footer mb-4 px-4">
                    <button
                        className="btn"
                        type="button"
                        onClick={handleSignOut}
                    >
                        <i className="bi bi-box-arrow-right"></i> Cerrar sesión
                    </button>
                </div>
            </div>
            <ModalComponent
                id='alertModal'
                title={modalTitle}
                show={showModal}
                setShow={setShowModal}
                callbackOnHide={handleCallbackOnHide}
                callbackData={callbackData}
            >
                <p>
                    {modalBody}
                </p>
                <div className='d-flex justify-content-end mt-4'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => {
                            setShowModal(false)
                        }}
                    >
                        Ok
                    </button>
                </div>
            </ModalComponent>
        </>

    )
}
